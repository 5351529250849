import React from "react"
import { Link } from "gatsby"
import techImg from "../../assets/images/new-imgs/technical-assistance.png"


const CustomerSupport = () => {
  return (
    <section id="customer-support">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={techImg} alt="Gazduire Web" loading="lazy"/>
            </div>
          </div>
          <div className="col-md-6 info-text">
            <h2 className="mb-5 h3-title">Asistența tehnică <span>24/7 Inclusă</span></h2>
            <p>Indiferent de provocarea tehnică pe care o întâmpini sau dacă ai pur și simplu o întrebare legată de serviciile noastre de hosting, echipa suport SiteBunker îți stă la dispoziție 24/7 pe parcursul întregului an.</p>
            <p className="mb-5 "> Tu contactează-ne și îți garantăm un răspuns prompt prin sistemul nostru de tickete.</p>
            <Link to="/contact/" className="view-more-button">Testează-ne disponibiliatea</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomerSupport;
