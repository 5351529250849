import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import IntroHostingPlans from "../components/HostingPlans/IntroHostingPlans"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import PromoInfoRow from "../components/PromoInfoRow/PromoInfoRow"
import InfoBlocks from "../components/Index/InfoBlocks"
import CustomerSupport from "../components/Index/CustomerSupport"
import SiteBenefits from "../components/SiteBenefits/SiteBenefits"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const GazduireWebSsd = () => {
    return (
        <Layout>
            <SEO title="Găzduire Web Sitebunker.ro - Servicii de Hosting Romania"
                 description="Ești în căutare de găzduire website cu specificații custom? Sitebunker.ro îți pune la dispoziție servicii  personalizate de Hosting Premium. Contactează-ne!"
            />
            <Navbar/>

            <IntroHostingBgWhite
                title={'Pachete de găzduire web pe servere performante, pentru un hosting eficient'}
                subtitle={'Alege pachetul potrivit nevoilor tale și bucură-te de siguranța serviciilor premium.'}
            />

            <HostingPlanBlock category="Web SSD" showPromos={true}/>

            <BannerArea
                pageTitle={["Găzduire website performantă"]}
                pageSubtitle="Hosting rapid prin LiteSpeed Web Server"
                pageDescription="La SiteBunker tu alegi pachetul de hosting potrivit pentru tine. Indiferent de platforma pe care o utilizezi și nevoile tale de găzduire website, îți punem la dispoziție chiar și pachete de hosting personalizate. "
                pageDescriptionTwo="Verifică opțiunile de găzduire website disponibile mai jos, iar în cazul în care dorești mai mult este suficient să ne contactezi. Efectuăm imediat o evaluare tehnică pentru cerințele tale, îți comunicăm tariful lunar și toate condițiile de care să ții cont."
                svgIllustration="web-ssd"
            />
            <FreeHosting title='Obține un domeniu .ro sau .eu gratuit pentru 1 an'
                         text1='Alege un pachet de hosting anual și ai domeniu gratis din partea noastră. Avem soluții personalizate pentru orice nevoie a website-ului tău.'
                         text2='Oferta este valabilă pentru toate pachetele de mai sus, mai puțin pachetul Start.'/>
            <PromoInfoRow/>
            <InfoBlocks/>
            <CustomerSupport/>
            <SiteBenefits/>
            <ClientFeedback
                title="Află ce spun clienții despre serviciile de"
                bold="hosting SiteBunker!"/>
            <Footer/>
        </Layout>
    )
}

export default GazduireWebSsd
