import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import { Link } from "gatsby"

const SiteBenefits = () => {
  return (
    <section id="site-benefits">
      <div className="container">
        <div className="title-row">
          <h2 className="h3-title text-center mb-4">Iată de ce să alegi serviciile de găzduire
            website <strong>SiteBunker:</strong></h2>
        </div>
        <div className="row">
          <div className="col-md-4 benefit-col">
            <img src={servIcon} loading="lazy" alt="" />
            <h3 className="benefits-title">HOSTING PREMIUM LA PREȚURI ACCESIBILE</h3>
            <p>Garantăm resurse premium de procesare, stocare și memorii RAM pentru nevoile tale de hosting prin
              pachetele pe care le poți testa gratuit 30 zile înainte de a le achiziționa. Solicită detalii <Link
                to="/contact/">aici.</Link></p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={notifIcon} loading="lazy" alt="" />
            <h3 className="benefits-title">BACKUP ZILNIC AUTOMAT ÎN LOCAȚII EXTERNE</h3>
            <p>Datele tale sunt în siguranță. Îți oferim GRATUIT backup automat, realizat într-o locație externă.
              Astfel, poți fi liniștit când vine vorba de website-ul tău, indiferent în ce platformă a fost construit
              acesta.
            </p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={antivirusIcon} loading="lazy" alt="" />
            <h3 className="benefits-title">SECURITATE AVANSATĂ ȘI PROTECȚIE ANTI-DDoS</h3>
            <p>Prin SiteBunker ai la dispoziție mai mult decât găzduire website. Ne ocupăm inclusiv de protecție.
              Securitatea serviciilor de hosting este îmbunătățită constant, inclusiv pentru atacurile DDoS.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SiteBenefits
