import React from "react"
import ReactTooltip from "react-tooltip"
import aboutUs from "../../assets/images/new-imgs/about-us-2.png"


const PromoInfoRow = () => {
  return (
  <section>
    <section id="promo-info-row">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 info-text">
            <h2 className="h3-title mb-3">Găzduire website la superlativ</h2>
            <p>Succesul tău online începe cu un hosting eficient, de aceea serviciile de găzduire website puse la dispoziție de SiteBunker
              sunt concepute astfel încât tu să te poți concentra pe ce contează. </p>
              <p>Gata cu grijile legate de hosting! Acum ai găsit furnizorul
              de hosting de încredere. Iar dacă ești sceptic în această privință, află că ai 30 de zile GRATUITE să ne pui la încercare.</p>

            <h2 className="h3-title mb-3 mt-5">Economisești 1 lună de hosting la plata pe 1 an</h2>
            <p>Pe lângă resursele premium de procesare, stocare și memorii RAM necesare nevoilor tale de hosting, la orice pachet achiziționat
              de la SiteBunker cu plata pe 1 an, economisești automat 1 lună.</p>
              <p>Tot ce trebuie să faci este să comanzi pachetul preferat, să optezi
              pentru plata anuală, iar noi îți vom oferi un discount echivalent cu o lună gratuită.</p>
          </div>
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={aboutUs} loading="lazy" alt="Gazduire Web" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="packages-intro">
      <div className="packages-area pb-2 hosting-plans-intro container-fluid pt-100">
        <div className="container">
          <div className="section-title section-title-800">
            <h3 className="h3-title align-items-center text-white">Transfer hosting gratuit de la un alt furnizor
              <i className="ml-1 bx bxs-message-rounded-error heartbeat"
                 data-for="info-message"
                 data-place="top"
                 data-tip="Daca vechiul dvs provider de hosting foloseste cPanel. *Migrarile se fac in intervalul Luni - Vineri 9-23"
                 data-effect="solid"
              />
            </h3>
            <ReactTooltip id="info-message" />
            <p className="text-white mb-50">Experiențele din trecut cu alți furnizori de găzduire website nu au avut un final fericit și cauți un nou partener care să-ți
              ofere servicii de hosting eficiente și personalizate pe cerințele tale? Află că la SiteBunker ai transferul gratuit, simplu și
              rapid.</p>
            <p className="text-white mb-50"> Echipa de suport se va ocupa de întregul proces, ba chiar te va ghida în alegerea resurselor necesare.</p>
          </div>
        </div>
      </div>
    </section>
  </section>

  )
}

export default PromoInfoRow
