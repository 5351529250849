import React from "react"

import "../../assets/css/Common/freeHosting.css"
import freeHostingImg from "../../assets/images/free-hosting-package.png";

const FreeHosting = ({title, text1, text2}) => {
    return (
        <section id="free-hosting">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 order-md-1 order-2">
                        <div className="position-relative text-right">
                            <img src={freeHostingImg} alt="Hosting Romania" loading="lazy"/>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-2 order-1">
                        <div className="info-text">
                            <h2 className="mb-5 h3-title">{title}</h2>
                            <p>{text1}</p>
                            <p>{text2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FreeHosting

